import React, { useEffect, useCallback, useState, useImperativeHandle, forwardRef, useRef } from 'react'
import classNames from 'classnames'

const Modal = (props, ref) => {
  const modalRef = useRef()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [])

  /**
   * Closes the modal when the `escape` key is pressed
   * @param  {Object}
   * @return {Void}
   */
  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsVisible(false)
    }
  }, [])

  /**
   * Shows the modal
   * @return {Void}
   */
  const showModal = () => {
    setIsVisible(true)
  }

  /**
   * Hides the modal
   * @return {Void}
   */
  const hideModal = (event) => {
    event.preventDefault()

    setIsVisible(false)
  }

  /**
   * Allow the `showModal()` and `isVisible` to be accessed from a parent component
   */
  useImperativeHandle(ref, () => {
    return { showModal, isVisible }
  })

  return (
    <div
      ref={modalRef}
      className={classNames({ hidden: !isVisible })}
    >
      <div
        className='fixed top-0 left-0 h-full w-full z-modal bg-black bg-opacity-75'
        onClick={hideModal}
      />
      <div
        className='fixed top-0 left-0 h-full w-full z-modal flex justify-center item items-center pointer-events-none'
      >
        <div className='flex justify-center w-full max-w-xl bg-white border-t-8 border-primary pt-12 pb-20 relative  pointer-events-auto overflow-auto max-h-screen'>
          <button
            className='block absolute w-6 h-6 top-0 right-0 mt-2 mr-2'
            aria-label='Close Modal'
            onClick={hideModal}
          >
            <span className='block absolute top-1/2 left-0 w-full border-b-2 border-primary transform rotate-45' />
            <span className='block absolute top-1/2 left-0 w-full border-b-2 border-primary transform -rotate-45' />
          </button>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default forwardRef(Modal)
